import React, { useEffect, useState } from "react";

const WebViewer = ({ data, mode = 'contained' }) => {


  const [url, setUrl] = useState(null);

  const extractHTML = (text) => {
    const regex = /```html\s+([\s\S]*?)\s+```/;
    const matches = text.match(regex);
    return matches ? matches[1] : null;
  };

  const containedStyle = {
    width: '100%', // 100% of the viewport width
    height: '600px'
  };

  const fullScreenStyle = {
    width: '100vw', // 100% of the viewport width
    height: '100vh', // 100% of the viewport height
    border: 'none', // no border
    margin: 0, // no margin
    padding: 0, // no padding
    overflow: 'hidden', // no scrollbars
    position: 'absolute', // positioning relative to the nearest positioned ancestor (or the body if none)
    top: 0, // top position
    left: 0, // left position
  }

  const style = mode == 'contained' ? containedStyle : fullScreenStyle




  useEffect(() => {
    // let htmlContent = extractHTML(data)
    // htmlContent = htmlContent || data;
    let htmlContent;

    // Check if 'data' is an object and not null (as typeof null also returns 'object')
    if (typeof data === 'object' && data !== null) {
      // Assuming 'htmlContent' is a property of the 'data' object
      htmlContent = data.htmlContent;
      htmlContent = extractHTML(htmlContent) || htmlContent;

    } else if (typeof data === 'string') {
      // If 'data' is a string, use it directly
      htmlContent = extractHTML(data) || data;
    } else {
      // Handle other types or undefined 'data'
      htmlContent = "Try another source."; // Or any fallback value you consider appropriate
    }

    // Create a Blob of the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });
    // Create a URL for the Blob
    setUrl(URL.createObjectURL(blob))

  }, [data]);

  return (
    <iframe id="webview" src={url} title="Preview" width="100%" style={style} />
  );
};
export default WebViewer