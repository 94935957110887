import { interpolate, spring } from 'remotion';
import { Animated, Move, Scale, Fade, Rotate, Size } from 'remotion-animated';


export class Scene {
  constructor() {
    this.objects = [];
    this.animations = [];
  }
  setAudio(value) {
    this.audio = value
  }
  setBackgroundGradient(value) {
    this.background = value
  }
  setVideo(value) {
    this.video = value
  }
  setBackgroundColor(value) {
    this.backgroundColor = value
  }
  setBackgroundImage(value) {
    this.backgroundImage = value
  }

  createObject(properties) {
    const object = new SceneObject(properties, this);
    this.objects.push(object);
    return object;
  }

  applyAnimations(frame, fps) {
    this.objects.forEach(object => object.applyAnimations(frame, fps));
  }
}

export class SceneObject {
  constructor(properties, scene) {
    this.id = scene.objects.length + 1;
    Object.assign(this, properties);
    this.animations = []; // Store animations specific to this object
    this.scene = scene; // Reference back to the scene for global context if needed
  }

  scale(options) {
    // Directly add a scaling animation to this object
    this.animations.push(Scale(options));
    return this; // Allow chaining
  }

  move(options) {
    // Directly add a moving animation to this object
    this.animations.push(Move(options));
    return this; // Allow chaining
  }
  fade(options) {
    // Directly add a moving animation to this object
    this.animations.push(Fade(options));
    return this; // Allow chaining
  }
  rotate(options) {
    // Directly add a moving animation to this object
    this.animations.push(Rotate(options));
    return this; // Allow chaining
  }
  size(options) {
    // Directly add a moving animation to this object
    this.animations.push(Size(options));
    return this; // Allow chaining
  }



  applyAnimations(frame, fps) {
    // Calculate and apply animations based on the frame
    const animatedStyle = { ...this.style };
    this.animations.forEach(animation => {
      // Apply animation based on type, similar logic to the functional approach
      // This part would use your linearAnimation, springAnimation, etc., utilities
      // Example for a linear type (pseudo-code, adapt as needed):
      // if (animation.type === 'linear') {
      //   animatedStyle[animation.property] = linearAnimation(frame, animation);
      // }
    });
    return animatedStyle;
  }
}

// Usage Example
// const myScene = new Scene();
// const myObject = myScene.createObject({ type: 'image', src: '...', style: {} })
//   .scale(1.5)
//   .move(100);
// Later in a render function
// myScene.applyAnimations(currentFrame, fps);
