import React from 'react';
import { Img, interpolate, useCurrentFrame, useVideoConfig, Video } from 'remotion';
import styled from 'styled-components';
import data from './res/data.mp4';
import gatsby from './res/gatsby.mp4';
import talk from './res/talk.mp4';
import tiktok from './res/tik-tok.mp4';
import twitter from './res/twitter.mp4';

const BigTitle = styled.div`
  font-family: ---apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 160px;
  text-align: left;
  line-height: 1;
  font-weight: bold;
`;

const SectionTitle = styled.div`
  font-family: ---apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 50px;
  font-weight: bold;
  margin-top: 50px;
`;

export const ShowCase = (props) => {

  const config = props;


  const { width, height } = useVideoConfig();
  const frame = useCurrentFrame();
  const translation = interpolate(frame, [0, 25, 250], [1500, 300, -3000]);

  return (
    <div style={{ flex: 1, backgroundColor: 'white', display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', marginLeft: 80 }}>
        <BigTitle>{config.title}</BigTitle>
      </div>
      <div style={{ flex: 1, transform: `translateY(${translation}px)` }}>
        {config.sections.map((section, index) => (
          <div key={index}>
            <SectionTitle>{section.caption}</SectionTitle> <br />
            {section.type === 'video' ? (
              <Video
                muted
                startFrom={section.startFrom}
                style={{ ...section.style, height: section.style.height || height / 2 }}
                src={section.src || 'https://placehold.co/600x400'}
              />
            ) : (
              <Img src={section.src || 'https://placehold.co/600x400'} style={section.style} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

