import React, { useEffect, useRef } from 'react';

const TimelineViewer = ({ data }) => {
  const timelineRef = useRef(null);

  useEffect(() => {

    new window.TL.Timeline(timelineRef.current, data);

  }, []);

  return (

    <div className="timeline-container">
      <div ref={timelineRef} id="timeline-embed" style={{ width: '100%', height: '600px' }} />
    </div>

  );
};

export default TimelineViewer;
