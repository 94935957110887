import React, { useState, useEffect } from 'react';

// Function to copy text to clipboard
const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    alert('URL copied to clipboard!');
  }, (err) => {
    console.error('Could not copy text: ', err);
  });
};


const ShareModal = ({ isOpen, onClose, shareContent, shareUrl, doShare }) => {
  if (!isOpen) return null;

  const handleShare = (platform) => {
    console.log(`Sharing on ${platform}:`, shareContent);
    doShare(platform)
    // Implement sharing logic here
    onClose();
  };
  // Effect to add an event listener to the document
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Ensure ref is set and the click is outside of the modal content
      if (event.target.id === 'modal-backdrop') {
        onClose();
      }
    };

    // Add when the component mounts
    document.addEventListener('mousedown', handleOutsideClick);

    // Return a function to be called when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]); // Only rerun if onClose changes



  return (
    <div id="modal-backdrop" className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white border-4 border-black p-12 rounded-lg text-center space-y-6" style={{ minWidth: '600px' }}>
        <h2 className="text-3xl font-bold">Share Your Creation</h2>
        <p>Let others know about your work.</p>
        {/* <div className="border-2 border-black p-6">
          <p>{shareContent}</p>
        </div> */}
        <div className="flex justify-between items-center border-2 border-black p-2 rounded-md">
          <input className="flex-1 text-sm" readOnly value={shareUrl} />
          <button
            className="ml-2 text-black bg-transparent border-2 border-black font-medium rounded-lg text-sm p-2 transition-all duration-300 hover:bg-black hover:text-white"
            onClick={() => copyToClipboard(shareUrl)}
          >
            <i className="fas fa-copy"></i> Copy
          </button>
        </div>
        <div className="flex justify-center gap-4">
          {/* Example share buttons with FontAwesome icons */}
          <button
            className="text-white bg-black border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:border-yellow-500 hover:shadow"
            onClick={() => handleShare('Twitter')}
          >
            <i className="fab fa-twitter"></i> Share it !
          </button>
          {/* <button
            className="bg-transparent border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:bg-black hover:text-white"
            onClick={() => handleShare('Facebook')}
          >
            <i className="fab fa-facebook-f"></i> Facebook
          </button> */}
          {/* Add more platform buttons as needed */}
        </div>
        {/* <button
          className="bg-transparent border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:bg-black hover:text-white"
          onClick={onClose}
        >
          Close
        </button> */}
      </div>
    </div>
  );
};

export default ShareModal;
