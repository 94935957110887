import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingHeader from "../components/shared/LandingHeader";
import AppCard from "../components/AppCard"; // Make sure this is the correct import path
import AppOutputPreview from "../components/AppOutputPreview";
import Hero from "../components/shared/Hero";
import AppListContainer from "../components/AppListContainer";
import { useApi } from '../callApi';
import AppInput from "../components/AppInput";
import MainAppGeneratorContainer from "../components/MainAppGeneratorContainer";
import LoadingIndicator from "../components/shared/LoadingIndicator";

const CallToActionSection = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-teal-400 text-white text-center py-12 px-4">
      <h2 className="text-3xl font-bold mb-4">Ready to Get Started?</h2>
      <p className="mb-8">Join thousands of individuals and companies already using MakeGen AI to enhance their projects.</p>
      <button onClick={() => window.location = '/dashboard'} className="bg-white text-blue-700 font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out hover:bg-blue-200">
        Sign Up Now
      </button>
    </div>
  );
};
const LandingPage = () => {
  const [selectedService, setSelectedService] = useState();
  const [showcases, setShowcases] = useState([]);
  const [output, setOutput] = useState();

  const [loading, setLoading] = useState(false);
  const { callApi } = useApi(true);

  const fetchShowCases = async () => {
    try {
      setLoading(true);
      // Assuming `callApi` is available to make the API call
      const response = await callApi(`/apps/featured`);
      console.log(response)
      setShowcases(response);
      setSelectedService(response[0]);
      setOutput(response[0].sample_data)

    } catch (err) {
      // TODO: handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShowCases();
  }, []);


  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // for medium screens and up
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640, // for small screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="min-h-screen bg-dark"> {/* Apply dark background and text color */}

      <LandingHeader />
      <Hero />
      <div className="container mx-auto px-4 py-8  bg-dark text-white">
        {loading && <LoadingIndicator message={''} />}
        <Slider {...sliderSettings} className="">
          {showcases?.map((showcase) => (
            <div key={showcase.id} onClick={() => { setSelectedService(showcase); setOutput(showcase.sample_data) }} className="p-4 cursor-pointer  hover:border-yellow-500 rounded-lg border-2  border-white ">
              <div className="flex items-center space-x-2" >
                <img className="w-8 h-8" src={`/assets/images/${showcase.output_icon}`} alt={`${showcase.name} icon`} />
                <h3 className="text-xl font-bold">{showcase.name}</h3>
              </div>
              <p>{showcase.description}</p>
            </div>
          ))}
        </Slider>



      </div>

      <div className="flex flex-col lg:flex-row gap-4 py-4 px-5  mt-8 bg-white">
        <div className="lg:w-1/2  rounded-lg">
          {/* <div className="p-4 bg-gray-100 rounded-t-lg">
            <h2 className="text-2xl font-bold text-center">Upload Your PDF</h2>
          </div> */}
          {/* {selectedService && <AppCard iconMode="output" app={selectedService.apps} />} */}
          {/* {selectedService && <AppInput app={selectedService.apps} />} */}
          {selectedService && <MainAppGeneratorContainer showBackButton={false} setOutputCallback={(output) => { setOutput(output) }} appId={selectedService.id} appSlug={selectedService.app_slug} />}


        </div>

        <div className="lg:w-1/2 flex justify-center items-center">

          {selectedService && (
            <div className="bg-white rounded-lg shadow-lg w-full">
              <div className="p-4 bg-gray-100 rounded-t-lg">
                <h2 className="text-2xl font-bold text-center capitalize">{selectedService?.output_type} Preview</h2>
              </div>
              <AppOutputPreview enableControls={false} readOnly={true} output={output} outputType={selectedService?.output_type} />
            </div>
          )}
        </div>
      </div>
      <CallToActionSection />
    </div>
  );
};

export default LandingPage;
