import React from 'react';
import { Player } from '@remotion/player';
import { useVideoConfig } from 'remotion';
import { TitleCard } from './components/TitleCard';
import { ShowCase } from './components/ShowCase'; // Adjust the path as necessary
import { Audio } from 'remotion';

import { Sequence } from 'remotion';
import { ConfigurableVideo } from './components/ConfigurableVideo';
// import MyVideo from './components/MyVideo';
import VideoScene from './components/VideoScene';
import { AudiogramComposition } from './podcast-video/Composition';

// Component mapping
const componentMapping = {
  TitleCard: TitleCard,
  ConfigurableVideo: ConfigurableVideo,
  Podcast: AudiogramComposition,
  VideoScene: VideoScene,
  Audio: Audio, // Directly use Remotion's Audio component

  // Add other components here as you create them
};

// Dynamically generated component based on configuration
const DynamicComposition = ({ config }) => {
  // Initialize a variable to keep track of the cumulative duration
  let cumulativeDuration = 0;

  return (
    <>
      {config.components.map((comp, index) => {
        const Component = componentMapping[comp.type];
        if (!Component) {
          console.error(`Component of type ${comp.type} not found.`);
          return null;
        }

        // Use the current value of cumulativeDuration as the starting point for this sequence
        const sequenceStart = cumulativeDuration;

        comp.durationInFrames = comp.durationInFrames || 150; // handle erors
        // Then, add this component's duration to the cumulative duration for the next iteration
        cumulativeDuration += comp.durationInFrames;


        return (
          <Sequence key={index} from={sequenceStart} durationInFrames={comp.durationInFrames}>
            <Component {...comp.props} />
          </Sequence>
        );
      })}
    </>
  );
};

// Wrapper component for Player
const VideoPreview = ({ config }) => {
  return (
    <Player
      component={() => <DynamicComposition config={config} />}
      compositionWidth={1920}
      compositionHeight={1080}
      style={{ 'width': '50%' }}
      fps={30}
      durationInFrames={config.components.reduce((acc, comp) => acc + (comp.durationInFrames || 150), 0)}
      controls
      loop
      autoPlay={false}
    />
  );
};

export default VideoPreview;
