import React, { useState, useEffect } from 'react';


const Modal = ({ isOpen, onClose, title, subtitle, children, showCloseButton = true, css = "flex justify-between items-center border-2 border-black p-2 rounded-md" }) => {
  if (!isOpen) return null;

  const handleShare = (platform) => {
    console.log(`Sharing on ${platform}:`, shareContent);
    // Implement sharing logic here
    onClose();
  };

  // Effect to add an event listener to the document
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Ensure ref is set and the click is outside of the modal content
      if (event.target.id === 'modal-backdrop') {
        onClose();
      }
    };

    // Add when the component mounts
    document.addEventListener('mousedown', handleOutsideClick);

    // Return a function to be called when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]); // Only rerun if onClose changes

  return (
    <div id="modal-backdrop" className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white border-4 border-black p-12 rounded-lg text-center space-y-6" style={{ minWidth: '600px' }}>
        <h2 className="text-3xl font-bold">{title}</h2>
        <p>{subtitle}</p>

        {children && (
          <div className={css}>
            {children}
          </div>
        )}
        {showCloseButton && <button
          className="bg-transparent border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:bg-black hover:text-white"
          onClick={onClose}
        >
          Close
        </button>}
      </div>
    </div>
  );
};

export default Modal;
