export const downloadIframeContent = (iframeId, filename) => {
  // Get the iframe element
  const iframe = document.getElementById(iframeId);
  if (!iframe) {
    return; // Exit if no iframe found
  }

  // Get the document inside the iframe
  const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

  // Get the HTML content of the iframe document
  const htmlContent = iframeDoc.documentElement.outerHTML;

  // Create a Blob from the HTML content
  const blob = new Blob([htmlContent], { type: 'text/html' });

  // Generate a URL for the Blob
  const blobUrl = URL.createObjectURL(blob);

  // Create a temporary anchor element to trigger the download
  const downloadLink = document.createElement('a');
  downloadLink.href = blobUrl;
  downloadLink.download = filename || 'iframe-content.html'; // Default filename if not provided
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(blobUrl); // Clean up the Blob URL
};

