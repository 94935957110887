// SignUpModal.js
import React, { useState, useEffect } from 'react';
import Logo from './Logo';

const SignUpModal = ({ isOpen, onClose, title = 'Make Something from Nothing !', message = 'Create an account to save and share your work.' }) => {
  if (!isOpen) return null;

  // Effect to add an event listener to the document
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Ensure ref is set and the click is outside of the modal content
      if (event.target.id === 'modal-backdrop') {
        onClose();
      }
    };

    // Add when the component mounts
    document.addEventListener('mousedown', handleOutsideClick);

    // Return a function to be called when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]); // Only rerun if onClose changes


  return (
    <div id="modal-backdrop" className="fixed inset-0 bg-black bg-opacity-30 z-50 flex justify-center items-center">
      <div className="bg-white border-4 border-black p-8 rounded-md text-center space-y-4">
        <div className='text-center justify-center'>
          <Logo containerClass={'flex items-center justify-center'} textClass={'text-lg md:text-xl font-semibold text-black justify-center items-center my-8  '} />
        </div>
        <h2 className="text-2xl font-bold">{title}</h2>

        <p>{message}</p>
        <div className='my-8 py-8'>
          <button
            className="text-black bg-transparent border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:bg-black hover:text-white"
            onClick={onClose}
          >
            No Thanks
          </button>
          <button
            className="text-white bg-black border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:border-yellow-500 hover:shadow"
            onClick={() => { window.location = '/dashboard' }}
          >
            Yes, Sign me up !
          </button></div>
      </div>
    </div>
  );
};

export default SignUpModal;
