import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import Modal from '../shared/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faRefresh } from '@fortawesome/free-solid-svg-icons'; // Assuming these are the correct names

const QuestionsOutputPreview = ({ data }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [interactiveMode, setInteractiveMode] = useState(false);
  const [counter, setCounter] = useState(0);

  const [showConfetti, setShowConfetti] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [results, setResults] = useState({});

  const reset = (questionIndex, answer, isCorrect) => {
    setCounter(0)
    setGameFinished(false)
    setScore(0)
    setCurrentQuestionIndex(0)
    setSelectedAnswers({})
    setModalOpen(false)
  };

  const handleAnswerSelect = (questionIndex, answer, isCorrect) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: answer,
    });

    if (isCorrect && !gameFinished) {
      setScore(prevScore => prevScore + 1);
    } else {

    }
    setCounter(c => c + 1)

    setTimeout(() => {
      if (questionIndex < data.questions.length - 1) {
        setCurrentQuestionIndex(questionIndex + 1);
      } else {
        //handleQuizCompletion();
      }
    }, 300); // Delay for the feedback animation
  };

  const handleQuizCompletion = () => {
    setGameFinished(true)
    setShowConfetti(score === data.questions.length);
    setResults({
      title: score === data.questions.length ? 'Congratulations!' : 'Your score!',
      description: score === data.questions.length ? ` You got them all right.. ${score}/${data.questions.length}..` : ` Practice some more.. ${score}/${data.questions.length}..`,
    });
    setModalOpen(true);
  };

  useEffect(() => {

    if (currentQuestionIndex == data.questions.length - 1)
      handleQuizCompletion()

  }, [score, counter])

  const getButtonClass = (questionIndex, answer) => {
    const isSelected = selectedAnswers[questionIndex] === answer;
    if (!isSelected) return 'border-gray-300 text-gray-800 hover:border-gray-500 hover:text-gray-600';

    const isCorrect = answer === data.questions[questionIndex].correct_answer;
    return isCorrect ? 'border-green-500 text-green-500' : 'border-red-500 text-red-500';
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">

      <img width={'30%'} src={data?.thumbnailUrl} />
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}

      {!interactiveMode && (
        <form className="space-y-6 flex-1" onSubmit={(e) => e.preventDefault()}>
          <button onClick={() => { setInteractiveMode(true) }}
            className="flex mx-auto py-10 items-center text-center justify-center mt-4 bg-black text-white font-medium border-2 border-black py-2 px-4 rounded-md hover:bg-opacity-90 transition-all duration-300 hover:border-yellow-500 hover:shadow"
          >
            <FontAwesomeIcon icon={faCheckCircle} />
            &nbsp;

            Start Quiz
          </button>
          {data?.questions?.map((item, questionIndex) => (
            <div key={questionIndex} className="space-y-2">
              <h3 className="font-semibold">{item.question}</h3>
              {item.answers.map((option, optionIndex) => (
                <label key={optionIndex} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name={`question_${questionIndex}`}
                    value={option}
                    checked={selectedAnswers[questionIndex] === option}
                    onChange={() => handleAnswerSelect(questionIndex, option, option === item.correct_answer)}
                  />
                  <span>{option}</span>
                </label>
              ))}
            </div>
          ))}
          <div className='justify-center flex'>
            <button onClick={() => { setInteractiveMode(true) }}
              className="flex mx-auto py-10 items-center text-center justify-center mt-4 bg-black text-white font-medium border-2 border-black py-2 px-4 rounded-md hover:bg-opacity-90 transition-all duration-300 hover:border-yellow-500 hover:shadow"
            >
              <FontAwesomeIcon icon={faCheckCircle} />
              &nbsp;

              Start Quiz
            </button>
            {/* <button onClick={handleQuizCompletion} className="mt-4 p-2 bg-vint justify-center text-2xl text-center text-white rounded">
              <FontAwesomeIcon icon={faCheckCircle} />
              &nbsp;
              Show Results
            </button> */}
          </div>
        </form>


      )}
      {interactiveMode && (
        < div className="text-center">
          <div className="mb-4">
            <p>Question {currentQuestionIndex + 1} of {data?.questions?.length}</p>
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
              <div className="bg-blue-600 h-2 rounded-full" style={{ width: `${((currentQuestionIndex + 1) / data.questions.length) * 100}%` }}></div>
            </div>
          </div>
          <h2 className="text-xl font-semibold">{data?.questions[currentQuestionIndex].question}</h2>
          <div className="mt-4 flex flex-col items-center">
            {data?.questions[currentQuestionIndex]?.answers.map((answer, index) => (
              <button
                key={index}
                className={`mt-2 px-4 py-2 border-2 rounded-lg ${getButtonClass(currentQuestionIndex, answer)}`}
                onClick={() => handleAnswerSelect(currentQuestionIndex, answer, answer === data.questions[currentQuestionIndex].correct_answer)}
              >
                {`${String.fromCharCode(65 + index)}. ${answer}`}
              </button>
            ))}
          </div>

        </div>

      )}


      {
        modalOpen && (
          <Modal showCloseButton={false} css="flex justify-between items-center  p-2 rounded-md" title={results.title} subtitle={results.description} isOpen={modalOpen} onClose={() => setModalOpen(false)}>
            {gameFinished && !showConfetti &&
              <>
                <img className='w-32 mx-auto' src="/assets/images/sad.png" />
                <br />
                <button onClick={reset}
                  className="flex mx-auto py-10 items-center text-center justify-center mt-4 bg-black text-white font-medium border-2 border-black py-2 px-4 rounded-md hover:bg-opacity-90 transition-all duration-300 hover:border-yellow-500 hover:shadow"
                >

                  <FontAwesomeIcon icon={faRefresh} />
                  &nbsp;

                  Hmm..Try Again ?
                </button>
              </>


            }
            {gameFinished && showConfetti &&
              <img className='w-32 mx-auto' src="/assets/images/success.png" />
            }
          </Modal>
        )
      }
    </div >
  );
};

export default QuestionsOutputPreview;

