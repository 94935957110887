import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneAlt, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import VoicePlayer from './VoicePlayer';
import { useState } from 'react';

const PodcastCard = ({ data, currentPodcast }) => {
  const [loading, setLoading] = useState(false)

  const sharePodcast = async () => {
    document.getElementById('share').click()

  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
        {data?.thumbnailUrl && <img src={data?.thumbnailUrl} className="w-full h-full object-cover" />}

        <div className="mt-4">
          <h3 className="text-l font-semibold">{currentPodcast?.user || ''} {currentPodcast?.name || ''} </h3>

          <p className="text-sm text-gray-600">
            <span className="font-semibold">  {data?.title || ''}
            </span>
          </p>
        </div>




        <VoicePlayer data={data} />


        <div className="mt-4 flex space-x-2 justify-center">
          <button onClick={sharePodcast} className={`py-2 px-4 text-sm text-white bg-black rounded-lg transition duration-300 font-medium ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'}`} disabled={loading}>
            <FontAwesomeIcon className='pr-2' icon={faShareAlt} />

            Share Podcast

          </button>


          {/* <button onClick={onGeneratePodcast} className={`py-2 px-4 text-sm text-white bg-black rounded-lg transition duration-300 font-medium ${isDataLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'}`} disabled={isDataLoading}>
          
                <Radio visible={true} colors={['#ffff', '#ffff', '#ffff']} width="50" height="50" wrapperStyle={{ display: 'inline-block' }} />
              ) : (

                'Save Episode'
              )}
            </button>} */}


          <a href={`${data.mediaUrl}`} download="podcast.mp3" className="py-2 px-4 text-sm text-white bg-blue-500 rounded-lg transition duration-300 font-medium hover:bg-blue-600">
            Download MP3
          </a>
        </div>
        {/* <div className="mt-4 space-y-2">
          {editMode ? (
            <button
              onClick={toggleEditSave}
              className={`py-2 px-4 text-sm text-white bg-green-500 rounded-lg transition duration-300 font-medium ${isDataLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}`}
              disabled={isDataLoading}
            >
              Save Podcast
            </button>
          ) : (
            <>
              <button
                onClick={toggleEditSave}
                className="py-2 px-4 text-sm text-white bg-black rounded-lg transition duration-300 font-medium hover:bg-gray-700"
              >
                Edit Podcast Cover
              </button>
              {!podcastGenerated && (
                <button
                  onClick={generatePodcast}
                  className="py-2 px-4 text-sm text-white bg-blue-500 rounded-lg transition duration-300 font-medium hover:bg-blue-700"

                >
                  {isDataLoading ? (
                    <Radio visible={true} colors={['#ffff', '#ffff', '#ffff']} width="50" height="50" wrapperStyle={{ display: 'inline-block' }} />
                  ) : (

                    'Generate Podcast'
                  )}
                </button>
              )}
              {podcastGenerated && (
                <div className="flex items-center justify-center">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="py-2 px-4 text-sm text-gray-700 bg-white border border-gray-300 rounded-l-md transition duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:border-transparent"
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    id="emailInput"
                  />
                  <button
                    onClick={sharePodcast}
                    className="py-2 px-4 text-sm text-white bg-green-600 border border-green-600 rounded-r-md transition duration-300 font-medium hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    style={{ height: 'fit-content' }}
                  >
                    Share Podcast
                  </button>
                </div>



                // <button
                //   onClick={sharePodcast}
                //   className="py-2 px-4 text-sm text-white bg-green-600 rounded-lg transition duration-300 font-medium hover:bg-green-800"
                // >
                //   Share Podcast
                // </button>
              )}
            </>
          )}
        </div> */}
      </div>
      {/* <PodcastModal generatePodcast={generatePodcast} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} /> */}

    </div>
  )
}
export default PodcastCard