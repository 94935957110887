import React, { useEffect, useState } from 'react';
import { AbsoluteFill, useCurrentFrame, useVideoConfig, Audio, staticFile, continueRender, delayRender, Video } from 'remotion';
import { Animated, Move, Scale, Fade } from 'remotion-animated';
// import { applyAnimations } from './animationUtils'; // Assuming applyAnimations is properly adjusted to work with Animated
import { preloadAudio, preloadImage, resolveRedirect } from "@remotion/preload";
import { PaginatedSubtitles } from '../audiogram/subtitles';
import '../audiogram/style.css';

const VideoScene = ({ scene }) => {
  const frame = useCurrentFrame();

  const subtitlesLineHeight = 98

  const { fps, durationInFrames } = useVideoConfig();
  const [subtitles, setSubtitles] = useState(null);
  const [handle] = useState(() => delayRender());

  if (scene.audio)
    preloadAudio(scene.audio)

  const preloadAssets = (object) => {
    const urlToLoad = object.src || object.source
    preloadImage(urlToLoad)
  }

  const renderObject = (object) => {
    // Apply animations to the object based on the current frame
    const animatedStyle = object.applyAnimations(object, scene.animations, frame, fps);

    preloadAssets(object)

    // Assuming your object contains a type that specifies what kind of object it is, e.g., 'text', 'image'
    // And a 'props' field for additional properties to pass to the Animated component
    switch (object.type) {
      case 'text':
        return (
          <Animated
            animations={object?.animations}
          >
            <div key={object.id} style={animatedStyle}>{object.text || object.content}</div>
          </Animated>

        );
      case 'image':
        return (
          <Animated animations={object?.animations}>
            <img key={object.id} style={animatedStyle} src={(object.src || object.source)} />
          </Animated>
        );
      case 'video':
        return (
          <Animated animations={object?.animations}>
            <video key={object.id} style={animatedStyle} src={(object.src || object.source)} />
          </Animated>
        );
      // Add more cases as needed for different object types
      default:
        return null;
    }
  };

  useEffect(() => {
    fetch(staticFile('subtitles.srt'))
      .then((res) => res.text())
      .then((text) => {
        console.log(text)
        setSubtitles(text);
        continueRender(handle);
      })
      .catch((err) => {
        console.log('Error fetching subtitles', err);
      });
  }, [handle]);

  const backgroundStyle = {
    background: scene.background,
    backgroundColor: scene.backgroundColor, // assuming backgroundColor property exists in scene object
    backgroundImage: `url(${scene.backgroundImage})`, // assuming backgroundImage property exists in scene object
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  };
  const audioOffsetInFrames = Math.round(0 * fps);

  const videoBackgroundStyle = {
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: '100%',
    height: '100%',
    objectFit: 'cover', // This will cover the entire area without stretching
    zIndex: '-1' // Make sure the video stays in the background
  };


  return <AbsoluteFill style={backgroundStyle} >
    {scene.audio && <Audio src={scene.audio} startFrom={0} />}
    {/* {scene.audio && <Audio src={staticFile('audio.mp3')} startFrom={10} />} */}
    {scene.video && <Video src={scene.video} style={videoBackgroundStyle} startFrom={0}></Video>
    }


    <div className="scene-container">
      {subtitles &&
        <div
          style={{ lineHeight: `${subtitlesLineHeight}px` }}
          className="captions"
        >
          <PaginatedSubtitles

            subtitles={subtitles}
            startFrame={audioOffsetInFrames}
            endFrame={audioOffsetInFrames + durationInFrames}
            linesPerPage={4}
            subtitlesTextColor={'rgba(255, 255, 255, 0.93)'}
            subtitlesZoomMeasurerSize={10}
            subtitlesLineHeight={subtitlesLineHeight}
            onlyDisplayCurrentSentence={true}
          />
        </div>
      }
      {/* {scene.objects.map(renderObject)} */}
    </div>




  </AbsoluteFill>;
};

export default VideoScene
