import React, { useEffect, useState, useRef } from 'react';
import mermaid from 'mermaid';
import { Canvg } from 'canvg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons'; // Assuming these are the correct names
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const MermaidChart = ({ data, onNodeClick }) => {
  const [isMermaidInitialized, setIsMermaidInitialized] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); // Initial zoom level
  const mermaidRef = useRef(null);



  const downloadMermaidChartAsImage = async () => {
    const svgElement = mermaidRef.current.querySelector('svg');
    if (!svgElement) {
      console.error('No SVG found in Mermaid chart.');
      return;
    }

    // Serialize the SVG to a string
    const serializer = new XMLSerializer();
    const svgStr = serializer.serializeToString(svgElement);

    // Open a new window and write the SVG string into it
    const printWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
    printWindow.document.write(`<html><head><title>Mermaid Chart</title>`);
    printWindow.document.write('</head><body>');
    printWindow.document.write(svgStr);
    printWindow.document.write('</body></html>');

    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/

    // Wait for the content to load before invoking the print dialog
    printWindow.onload = function () {
      printWindow.print();
      printWindow.close();
    };
  };



  const extractMermaidChart = (text) => {
    const mermaidRegex = /```mermaid\s+([\s\S]*?)\s+```/;
    const matches = text.match(mermaidRegex);
    return matches ? matches[1] : null;
  };

  //const chart = extractMermaidChart(data);
  let chart = data['mermaidMarkdown']
  chart = chart.replace(/\\n/g, '\n')

  useEffect(() => {
    mermaid.initialize({
      theme: 'neutral',
      startOnLoad: false,
      maxEdges: 1000, // Adjust as needed

      themeCSS: `
          .node rect, .node polygon, .node ellipse {
        fill: #fff; /* White background */
        stroke: #000; /* Black border */
        stroke-width: 2px; /* 2px thick border */
      }
      /* Add more custom styles as needed */`
      // Include any other configuration here
    });

    setIsMermaidInitialized(true);
  }, []);

  useEffect(() => {
    if (isMermaidInitialized && chart && mermaidRef.current) {
      try {
        mermaidRef.current.innerHTML = chart;
        mermaid.init(undefined, mermaidRef.current);
      } catch (err) {
        console.error('Mermaid rendering error:', err);
      }
    }
  }, [chart, isMermaidInitialized]);

  // Function to handle zoom in
  const zoomIn = () => {
    setZoomLevel(prevZoomLevel => prevZoomLevel + 0.1);
  };

  // Function to handle zoom out
  const zoomOut = () => {
    setZoomLevel(prevZoomLevel => Math.max(0.1, prevZoomLevel - 0.1)); // Prevent zooming out too much
  };

  // Apply zoom effect
  useEffect(() => {
    if (mermaidRef.current) {
      mermaidRef.current.style.transform = `scale(${zoomLevel})`;
    }
  }, [zoomLevel]);

  // return (
  //   <TransformWrapper
  //     defaultScale={1}
  //     defaultPositionX={200}
  //     defaultPositionY={100}
  //   >
  //     {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
  //       <div className='w-full mx-auto text-center'>
  //         <div className="tools">
  //           <button onClick={() => zoomIn()} className="mx-2 p-2 bg-white border-2 border-black text-black rounded">
  //             <FontAwesomeIcon icon={faMagnifyingGlassPlus} />

  //           </button>
  //           <button onClick={() => zoomOut()} className='className="mx-2 p-2 bg-white border-2 border-black text-black rounded'>
  //             <FontAwesomeIcon icon={faMagnifyingGlassMinus} />

  //           </button>
  //           <button onClick={() => resetTransform()} className='className="mx-2 p-2 bg-white border-2 border-black text-black rounded' >Reset</button>
  //         </div>
  //         <TransformComponent>
  //           <div ref={mermaidRef} style={{ width: "100%", height: "100%" }}></div>
  //         </TransformComponent>
  //       </div>
  //     )
  //     }
  //   </TransformWrapper >
  // );
  return (
    <>
      <div className="flex justify-center my-2">
        <button onClick={zoomIn} className="mx-2 p-2 bg-white border-2 border-black text-black rounded">
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} />

        </button>
        <button onClick={zoomOut} className="mx-2 p-2 bg-white border-2 border-black text-black rounded"><FontAwesomeIcon icon={faMagnifyingGlassMinus} /></button>
        <button onClick={downloadMermaidChartAsImage} className="mx-2 p-2 bg-black text-white rounded">Download </button>

      </div>
      <div key={chart} className="mermaid" ref={mermaidRef} style={{ transition: 'transform 0.2s ease-in-out', minHeight: '1000px' }}></div>
    </>
  );
};

export default MermaidChart;
