import styled from 'styled-components';

const BigTitle = styled.div`
  font-family: ---apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 160px;
  text-align: left;
  line-height: 1;
  font-weight: bold;
`;
export const TitleCard = ({ title, subtitle }) => {
  // Using Remotion utilities here for styling or layout if needed
  return (
    <div style={{ textAlign: 'center', padding: 20 }}>
      <BigTitle>{title}</BigTitle>
      <h2>{subtitle}</h2>
    </div>
  );
};
