import Typed from 'typed.js';
import React, { useEffect, useRef } from 'react';

const Hero = () => {
  const videoSrc = 'https://qtwobmfyhbevtzpgzxgu.supabase.co/storage/v1/object/public/assets/makegen-ai-video.mp4?t=2024-03-29T12%3A41%3A46.919Z';
  const el = useRef(null);
  const typed = useRef(null);

  useEffect(() => {
    const options = {
      strings: [
        "Make PDF into Podcast",
        "Make Podcast into Newsletter",
        "Make Podcast into Website",
        "Make PDF into Quiz",
        // Add more strings here that describe other functionalities
      ],
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 1500,
      startDelay: 500,
      loop: true,
      showCursor: true,
      cursorChar: '|',
      contentType: 'html',
    };

    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
  }, []);

  return (
    <header className="relative bg-white py-10 md:py-16" style={{ backgroundColor: '#091118' }}>
      <div className="container mx-auto px-4 md:px-6 relative">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 z-10">
            <h2 className="text-2xl md:text-4xl font-bold text-white mb-3">
              <span ref={el}></span>
            </h2>
            <p className="text-white text-lg mb-5">Make Something from Nothing. <span className="text-sm">©</span></p>
            {/* <a href="/dashboard" className="bg-black text-white px-6 py-3 rounded-lg hover:bg-gray-800 transition-colors">Get Started</a> */}
          </div>
          <div className="w-full md:w-1/2">
            <video autoPlay loop muted playsInline className="w-full h-auto rounded-lg shadow-lg">
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hero;
