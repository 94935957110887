import React, { useState, useEffect } from 'react';
// Make sure to have the correct path to your logo

const defaultSteps = [
  { title: 'Reading...', status: 'pending' },
  { title: 'Processing...', status: 'pending' },
  { title: 'Generating...', status: 'pending' },
];

const AppStatusIndicator = ({ steps = defaultSteps }) => {
  const [currentSteps, setCurrentSteps] = useState(steps);

  // Function to simulate the step completion
  const simulateProgress = () => {
    let delay = 0;

    currentSteps.forEach((step, index) => {
      if (step.status === 'pending') {
        setTimeout(() => updateStepStatus(index, 'in_progress'), delay);
        delay += 1000;

        if (step.title == 'Generating...')
          return

        setTimeout(() => updateStepStatus(index, 'completed'), delay);
        delay += 2000;
      }
    });
  };

  // Update a single step's status
  const updateStepStatus = (index, status) => {
    setCurrentSteps(prevSteps =>
      prevSteps.map((step, i) =>
        i === index ? { ...step, status: status } : step
      )
    );
  };

  useEffect(() => {
    simulateProgress();
  }, []);

  // Function to return appropriate icon based on status
  const statusIcon = (status) => {
    switch (status) {
      case 'in_progress':
        return <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>;
      case 'completed':
        return <svg className="w-8 h-8 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg>;
      case 'pending':
      default:
        return <div className="rounded-full h-8 w-8 border-2 border-gray-300"></div>;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <img src={'/assets/logos/logo.png'} alt="MakeGen AI Logo" className="w-16 h-16 mb-8" />
      <div className="space-y-4">
        {currentSteps.map((step, index) => (
          <div key={index} className="flex items-center">
            <span className="mr-4">{statusIcon(step.status)}</span>
            <span className={`text-xl font-medium ${step.status === 'completed' ? 'text-green-500' : step.status === 'in_progress' ? 'text-blue-500' : 'text-gray-400'}`}>
              {step.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppStatusIndicator;
