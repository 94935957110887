import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import VideoPreview from "./VideoPreview";

const DemoVideo = ({ config }) => {
  /// Scene 1: Discovering Egypt
  const [videoConfig, setVideoConfig] = useState()

  function extractJavaScriptCode(config) {
    const regex = /```javascript([\s\S]+?)```/;
    const match = config.match(regex);

    if (match && match[1]) {
      return match[1].trim(); // Extracted JavaScript code
    } else {
      return config; // Return original config if not found
    }
  }

  config = `
//Scene 1: Hook
const scene1 = new Scene();
scene1.setVideo('https://makegen.ai/api/video?query=business');
scene1.setBackgroundImage('https://images.unsplash.com/photo-1502481851512-e9e2529bfbf9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=768&ixid=MnwxfDB8MXxyYW5kb218MHx8bGFuZHNjYXBlfHx8fHx8MTcxMTYzNTI5OQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1024');
scene1.setAudio('https://qtwobmfyhbevtzpgzxgu.supabase.co/storage/v1/object/public/user_media/8dd01852-5c67-4a32-8c0d-4314296a3f3e.mp3?');

const textOverlay1 = scene1.createObject({
  type: 'text',
  text: 'Have you ever dreamed of exploring ancient wonders?',
  style: {
    fontFamily: 'var(--sans)',
    fontSize: '48px',
    color: 'white',
    fontWeight: '600',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
});
textOverlay1.fade({ to: 1, start: 0, duration: 5 });
textOverlay1.scale({ from: 0.8, to: 1, duration: 10, start: 5 });

// Scene 2: Introduce the Problem
const scene2 = new Scene();
scene2.setBackgroundColor('linear-gradient(to bottom, #D2B48C, #F5F5DC)');
const textOverlay2 = scene2.createObject({
  type: 'text',
  text: "Many overlook the hidden treasures of Egypt's history.",
  style: {
    fontFamily: 'var(--sans)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    fontSize: '48px',
    color: '#8B4513',
    fontWeight: '600',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
});
textOverlay2.fade({ to: 1, start: 21, duration: 10 });

// Scene 3: Show the Impact
const scene3 = new Scene();
scene3.setBackgroundImage('https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcQJBgttQ6QAPcC6JkVikV95yyIvOwJyiLS4FW3Z1AtqFZGimWXoYr36jHFKxjdxbWs2t6wCudlyH0pO4LNtHVPswr0hq8TZyKTLfvTB9g');
const textOverlay3a = scene3.createObject({
  type: 'text',
  text: "Over 50% of Egypt's population relies on tourism for livelihood.",
  style: {
    fontFamily: 'var(--sans)',
    fontSize: '36px',
    color: '#FFFACD',
    fontWeight: '500',
    textAlign: 'center',
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
});
const textOverlay3b = scene3.createObject({
  type: 'text',
  text: "Preserving cultural heritage is essential for communities.",
  style: {
    fontFamily: 'var(--sans)',
    fontSize: '36px',
    color: '#FFFACD',
    fontWeight: '500',
    textAlign: 'center',
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
});
textOverlay3a.fade({ to: 1, start: 41, duration: 5 });
textOverlay3a.scale({ from: 1, to: 1.1, duration: 5, start: 46 });
textOverlay3b.fade({ to: 1, start: 51, duration: 5 });
textOverlay3b.scale({ from: 1, to: 1.1, duration: 5, start: 56 });

// Scene 4: Introduce the Solution (Your Product)
const scene4 = new Scene();
scene4.setBackgroundImage('http://localhost:8000/api/image?query=1024x768?nile,cruise');
const textOverlay4 = scene4.createObject({
  type: 'text',
  text: "Experience Egypt's past and present with our guided tours.",
  style: {
    fontFamily: 'var(--sans)',
    fontSize: '48px',
    color: '#FFFFFF',
    fontWeight: '600',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
});
textOverlay4.fade({ to: 1, start: 61, duration: 10 });

// Scene 5: Call to Action
const scene5 = new Scene();
scene5.setBackgroundImage('http://localhost:8000/api/image?query=1024x768?nile,sunset');
const textOverlay5 = scene5.createObject({
  type: 'text',
  text: "Begin your adventure today and uncover Egypt's magic.",
  style: {
    fontFamily: 'var(--sans)',
    fontSize: '48px',
    color: '#FFD700',
    fontWeight: '600',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
});
textOverlay5.fade({ to: 1, start: 81, duration: 5 });
textOverlay5.scale({ from: 1, to: 1.05, duration: 10, start: 86 });

const scenes = [scene1, scene2, scene3, scene4, scene5];
scenes;
  `

  const code = extractJavaScriptCode(config)

  useEffect(() => {
    import('./components/animationUtils').then(({ Scene, SceneObject }) => {
      const scenes = eval(code)
      const sceneComponents = []
      scenes.forEach(scene => {
        if (scene) {
          sceneComponents.push(
            {
              "type": "VideoScene",
              "props": {
                "scene": scene

              },
              "durationInFrames": 5000,
            }
          )

        }


      });
      setVideoConfig({
        components: sceneComponents
      });

    });

  }, [code])




  return (
    <>



      {videoConfig && <VideoPreview config={videoConfig} />}

    </>
  )

}
export default DemoVideo