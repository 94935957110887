import { useAuth0 } from "@auth0/auth0-react";

const Logo = ({ imageOnly = false, containerClass = 'flex items-center', imgClass = 'h-8 md:h-10 mr-3', textClass = 'md:text-xl lg:text-lg font-semibold text-white md:block hidden', }) => {

  const { isAuthenticated } = useAuth0();

  return (

    <div className={containerClass}>
      <a href={isAuthenticated ? '/dashboard' : '/'} className="flex items-center no-underline">
        <img src='/assets/logos/logo.png' alt='MakeGen Logo' className={imgClass} />
        {!imageOnly && <h1 className={textClass}>MakeGen AI</h1>}
      </a>
    </div>
  )
}
export default Logo