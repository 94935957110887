import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from '../callApi';
import LandingHeader from "../components/shared/LandingHeader";
import AppOutputPreview from "../components/AppOutputPreview";
import { Helmet } from 'react-helmet-async';
import WebViewer from "../components/viewers/WebViewer";

const SharePage = () => {
  const { runId } = useParams();
  const { callApi } = useApi(true);
  const [output, setOutput] = useState();
  const [currentApp, setCurrentApp] = useState()
  const [currentRun, setCurrentRun] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetch = async () => {
    try {
      setIsLoading(true);
      const response = await callApi(`/user/runs/` + runId);
      setOutput(response.data);
      setCurrentApp(response.apps)
      setCurrentRun(response)
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  // Placeholder image and title, update with dynamic content as needed
  const pageTitle = currentApp ? currentApp?.name : "App Output";
  const pageImage = output ? output.thumbnailUrl : "https://makegen.ai/assets/logos/logo.pnghttps://makegen.ai/assets/logos/logo.png";
  const pageDescription = currentApp ? `See the output of ${currentApp.name}` : "Check out this app output on MakeGen.ai";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImage} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={pageImage} />
      </Helmet>

      {currentApp?.output_type == 'website' && output && (
        <WebViewer data={output} mode="fullscreen" />

      )}

      {currentApp?.output_type != 'website' && output && (
        <>
          <LandingHeader />
          <AppOutputPreview readOnly={true} run={currentRun} runId={runId} appId={currentApp?.id} output={output} outputType={currentApp.output_type} />

        </>

      )}


    </>
  );
};

export default SharePage;
