import React, { useEffect, useState } from "react";
import QuestionsOutputPreview from './viewers/QuestionsOutputPreview';
import MermaidChart from './viewers/MermaidChart'; // This would be your Chart component that you need to implement.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faChartBar, faBolt, faShareAlt, faSave, faL, faMagic, faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons'; // Assuming these are the correct names
import VoicePlayer from './viewers/VoicePlayer';
import GraphViewer from './viewers/GraphViewer';
import TimelineViewer from './viewers/TimelineViewer';
import SlidesViewer from './viewers/SlidesViewer';
import WebViewer from './viewers/WebViewer';

import { useApi } from '../callApi';
import { useAuth0 } from "@auth0/auth0-react";
import SignUpModal from "./shared/SignupModal";
import ShareModal from "./shared/ShareModal";
import Modal from "./shared/Modal";

import VideoPlayer from "./viewers/VideoPlayer";
import LoadingIndicator from './shared/LoadingIndicator';
import ChatComponent from "./viewers/ChatComponent";
import PodcastCard from "./viewers/PodcastCard";
import { downloadIframeContent } from "../helpers";
import VideoPreview from "../video-generator/VideoPreview";
import DynamicVideoPreview from "./viewers/DynamicVideoPreview";
import PodcastPreview from "../video-generator/PodcastPreview";

const AppOutputPreview = ({ appId, runId, run, output, outputType, readOnly = false, enableControls = true }) => {
  const { callApi } = useApi();
  const { isAuthenticated } = useAuth0();
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [runTitle, setRunTitle] = useState(run?.name || "");

  const [loading, setLoading] = useState(false);
  const webBaseUrl = process.env.REACT_APP_WEB_BASE_URL;


  const renderOutput = () => {
    if (!output) {
      return <div>No output to display.</div>;
    }

    switch (outputType) {
      case 'pdf-to-web':
        // Assuming 'output' is the URL to the generated web content
        return <iframe src={output} title="Web Preview" style={{ width: '100%', height: '500px' }} />;
      case 'mermaidChart':
        // Assuming 'output' is the data needed by the Chart component
        return <MermaidChart data={output} />;
      case 'chart':
        // Assuming 'output' is the data needed by the Chart component
        return <MermaidChart data={output} />;
      case 'voice':
        // Assuming 'output' is the data needed by the Chart component
        return <PodcastCard data={output} />;
      case 'podcast':
        // Assuming 'output' is the data needed by the Chart component
        return <PodcastPreview config={output} />
        // return <PodcastCard data={output} />;
        return
      case 'webview':
        // Assuming 'output' is the data needed by the Chart component
        return <WebViewer data={output} />;
      case 'website':
        // Assuming 'output' is the data needed by the Chart component
        return <WebViewer data={output} />;
      case 'dashboard':
        // Assuming 'output' is the data needed by the Chart component
        return <WebViewer data={output} />;
      case 'newsletter':
        // Assuming 'output' is the data needed by the Chart component
        return <WebViewer data={output} />;
      case 'article':
        // Assuming 'output' is the data needed by the Chart component
        return <WebViewer data={output?.htmlContent} />;
      case 'video':
        // Assuming 'output' is the data needed by the Chart component
        return <DynamicVideoPreview config={output} />;
      case 'reel':
        // Assuming 'output' is the data needed by the Chart component
        return <DynamicVideoPreview config={output} />;
      case 'graph':
        // Assuming 'output' is the data needed by the Chart component
        return <GraphViewer data={output} />;
      case 'timeline':
        // Assuming 'output' is the data needed by the Chart component
        return <TimelineViewer data={output} />;
      case 'chatbot':
        // Assuming 'output' is the data needed by the Chart component
        return <ChatComponent isStreaming={true} data={output} title="Ask me anything.." placeholder={'type in a question about your content..'} demo='/demo' showPrompts={true} containerClassName="max-w-2xl mx-auto" />;
      case 'slides':
        // Assuming 'output' is the data needed by the Chart component
        return <SlidesViewer data={output} />;
      case 'questions':
        // Assuming 'output' is the data needed by the Chart component
        return <QuestionsOutputPreview data={output} />;
      // Add more cases for other app types
      default:
        // If it's not a special format, assume it's JSON and display it prettily
        return (
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
            <p>Could not load</p>
          </pre>
        );
    }
  };

  // Function to handle the downloadOutput action
  const downloadOutput = () => {
    if (!isAuthenticated) {
      // If user is not authenticated, show modal
      setAuthModalOpen(true);
      return;
    }

    if (outputType === 'mermaidChart') {
      // Example: Convert Mermaid chart to an image and download it
      console.log('Download Mermaid chart as image');
      downloadMermaidChartAsImage();
      // Implementation depends on how you render and can capture Mermaid charts
    } else if (outputType === 'image' || outputType === 'voice' || outputType === 'video') {
      // Directly download the image, voice, or video
      const link = document.createElement('a');
      link.href = output.mediaUrl; // Assuming 'output' is a direct URL to the resource

      link.download = output.mediaUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (outputType === 'webview') {
      // Assuming 'output' contains the Blob URL from WebViewer
      downloadIframeContent('webview', 'output.html');

      // Specific implementation would vary
    } else {
      // For other types, you might convert the output to a file and download
      console.log('Download other types of output');
    }
  };





  const share = async () => {
    let url = `${webBaseUrl}/share/${runId}`

    let title = 'Just generated this with an Generative AI on makegen.ai !'
    if (navigator.share) {
      // Web Share API is available
      try {
        await navigator.share({
          title: title,
          // text: title,
          url: url
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing content', error);
      }
    } else {
      // Fallback, open a new window with a social media share URL
      const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
      window.open(shareUrl, '_blank', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
    }
  };

  const handleEdit = () => {
    if (!runId)
      return handleSave()

    let url = `/user/runs/iterate/${runId}`
    window.location = url

  }

  // Function to handle the share action
  const handleShare = () => {
    if (!isAuthenticated) {
      // If user is not authenticated, show modal
      setAuthModalOpen(true);
      return;
    }

    setShareModalOpen(true)



    console.log('Share clicked');
  };

  // Function to handle the save action
  const handleSave = async () => {
    if (!isAuthenticated) {
      setAuthModalOpen(true);
      return;
    }

    if (!runTitle)
      setModalOpen(true)
    else
      doSave()


  };

  const doSave = async () => {
    try {

      setLoading(true)
      let data = await callApi(`/user/apps/save?app_id=${appId}&title=${runTitle}`, { 'method': 'POST' }, output);
      setLoading(false)
      window.location = '/user/runs/' + data.uuid
      setModalOpen(false)


    } catch {
      setModalOpen(false)

      //@TODO: handle error
      setLoading(false)


    }

  }

  useEffect(() => {
    renderOutput()

  }, [output])



  return (
    <div className="app-output-preview">
      {/* Header */}
      {loading && <LoadingIndicator fullscreen={true} message={'Saving your creation..'} />}

      {enableControls &&
        <div className="flex justify-between items-center mb-4 bg-primary px-4 py-2 rounded-t-lg border-b-2 border-secondary">
          <h2 className="text-lg font-bold">{runTitle}</h2>
          <div className="flex gap-2">
            {readOnly == false &&
              <button id="edit" onClick={() => { handleEdit() }}
                className="text-white bg-black border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:border-yellow-500 hover:shadow"
              >
                <FontAwesomeIcon icon={faMagicWandSparkles} size={'1x'} color={'#fbbf24'} />

                &nbsp; Edit with AI
              </button>}
            <button id="share" onClick={handleShare}
              className="text-black bg-white border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:border-yellow-500 hover:shadow"
            >
              <FontAwesomeIcon size={'1x'} icon={faShareAlt} />

              &nbsp; Share
            </button>
            {readOnly == false && <button onClick={handleSave}
              className="text-black bg-white border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:border-yellow-500 hover:shadow"

            >
              <FontAwesomeIcon size={'1x'} icon={faSave} />

              &nbsp; Save
            </button>}
            {readOnly == false && <button onClick={downloadOutput}
              className="text-black bg-white border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:border-yellow-500 hover:shadow"

            >
              <FontAwesomeIcon size={'1x'} icon={faDownload} />

              &nbsp; Download
            </button>}
          </div>
        </div>
      }
      {/* Output Content */}
      <div className="p-4">
        {renderOutput()}
      </div>
      {/* Footer with additional actions */}
      <div className="flex justify-end bg-gray-100 px-4 py-2 rounded-b-lg">
        {/* <button className="text-blue-500 hover:text-blue-600 transition ease-in-out duration-150">More Actions...</button> */}
        {/* <button className="text-dark text-sm hover:text-blue-600 transition ease-in-out duration-150">MakeGen AI may produce inaccurate results or information...</button> */}

      </div>

      <SignUpModal isOpen={authModalOpen} onClose={() => setAuthModalOpen(false)} />
      <ShareModal doShare={share} shareUrl={`${webBaseUrl}/share/${runId}`} isOpen={shareModalOpen} onClose={() => setShareModalOpen(false)} />
      <Modal title={'Save your Creation'} isOpen={modalOpen} onClose={() => setModalOpen(false)} >

        <input

          key={'runTitle'}
          type="text"
          onChange={(e) => setRunTitle(e.target.value)}
          className="input w-full p-2 max-w-xs text-black bg-white"
          placeholder={"Give it a name.."}
        />

        <button
          className="text-white bg-black border-2 border-yellow-500 font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:bg-black hover:text-white"
          onClick={doSave}
        >
          Save it !
        </button>

      </Modal>


    </div>
  );
};

export default AppOutputPreview;
