import React from 'react';
import { Player } from '@remotion/player';
import { Composition, staticFile } from 'remotion';
import { AudioGramSchema, AudiogramComposition, fps } from './podcast-video/Composition';
import './podcast.css';

// Wrapper component for Player
const PodcastPreview = ({ config }) => {
  return (
    <Player
      className='mx-auto'

      component={() =>
        <AudiogramComposition


          // Audio settings
          audioOffsetInSeconds={0}

          // Title settings
          audioFileName={config?.mediaUrl}
          coverImgFileName={config?.thumbnailUrl}
          titleText={config?.titleText}
          titleColor={'rgba(186, 186, 186, 0.93)'}

          // Subtitles settings
          subtitlesFileName={config?.subtitlesUrl}
          onlyDisplayCurrentSentence={true}
          subtitlesTextColor={'rgba(255, 255, 255, 0.93)'}
          subtitlesLinePerPage={4}
          subtitlesZoomMeasurerSize={10}
          subtitlesLineHeight={98}

          // Wave settings
          waveColor={'#a3a5ae'}
          waveFreqRangeStartIndex={7}
          waveLinesToDisplay={29}
          waveNumberOfSamples={'256'} // This is string for Remotion controls and will be converted to a number
          mirrorWave={true}
          durationInSeconds={29.5}


        />

      }
      compositionWidth={1080}
      compositionHeight={1080}
      durationInFrames={4500}
      style={{ 'width': '75%' }}
      fps={fps}
      controls

      loop
      autoPlay={false}
    />
  );
};

export default PodcastPreview;
