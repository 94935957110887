import mixpanel from 'mixpanel-browser';

const environmentMode = process.env.REACT_APP_ENV_MODE || '';

const track_event = (event_name, data) => {
  if (process.env.NODE_ENV === 'production') {
    mixpanel.track(event_name, {
      ...data,
      environment: 'production' + environmentMode
    });
  } else {
    // Optional: Setup for development (e.g., logging for development)
    console.log("Mixpanel is disabled in development mode.");
  }

}
const track_error = (event_name = 'Error Event', error) => {
  if (process.env.NODE_ENV === 'production') {
    mixpanel.track(event_name, {
      ...error,
      environment: 'production' + environmentMode
    });
  } else {
    // Optional: Setup for development (e.g., logging for development)
    console.log("Mixpanel is disabled in development mode.");
  }

}

// Add to your existing tracking utility file

const identifyUser = (user) => {
  if (process.env.NODE_ENV === 'production') {
    mixpanel.identify(user?.sub); // Assuming user.sub is a unique identifier for the user
    mixpanel.people.set({
      $email: user?.email, // Assuming the user object contains an email field
      $name: user?.name, // Assuming the user object contains a name field
      // You can add more properties relevant to the user
    });
    mixpanel.track('Dashboard View', { // Track dashboard page view
      environment: 'production' + environmentMode
    });
  }
};

export default { track_event, track_error, identifyUser };
