import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';

const ErrorIndicator = ({ fullscreen, message, description, onClose }) => {
  return (
    <>
      {
        fullscreen && (
          <div className="fixed inset-0 bg-white bg-opacity-75 flex flex-col justify-center items-center text-center">
            <FontAwesomeIcon icon={faExclamationTriangle} size="4x" className="text-red-600" />
            {message && <>
              <h2 className="mt-4 text-xl font-bold">{message}</h2>

              <h3>{JSON.stringify(description)}</h3>

              <button
                onClick={onClose}
                className="mt-4 bg-black text-white font-bold py-2 px-4 rounded"
                aria-label="Close error message"
              >
                Close
              </button>
            </>
            }
          </div>
        )
      }
      {
        !fullscreen && (
          <div className="flex flex-col justify-center items-center h-full text-center">
            <FontAwesomeIcon icon={faExclamationTriangle} size="3x" className="text-red-600" />
            {message && <h2 className="text-lg font-semibold">{message}</h2>}

          </div>
        )
      }
    </>
  );
};

export default ErrorIndicator;
