import React, { useState, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import Layout from '../shared/Layout';
import PageHeader from '../shared/PageHeader';
import { useApi } from '../../callApi';
import { InfinitySpin } from 'react-loader-spinner';

const GraphViewer = ({ data }) => {
  //const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const graphData = data

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //setGraphData({ nodes: mockNodes, links: mockLinks })

    setIsLoading(true)
    async function fetchData() {
      try {
        // This endpoint should return only top-level nodes like "People", "Places", etc.
        const response = await callApi('/lifegraph/top');
        const data = response
        console.log(data)
        setGraphData(data);
        setIsLoading(false)

      } catch (error) {
        setIsLoading(false)

        console.error("Error fetching the graph data:", error);
      }
    }

    fetchData();
  }, []);


  const handleNodeClick = async node => {
    console.log(`Clicked on node: ${node.id}`);
    return

    try {
      // This endpoint should return nodes related to the clicked node type (e.g., specific people for "People").
      const response = await fetch(`http://0.0.0.0:8000/api/lifegraph/nodes/${node.id}`);
      const data = await response.json();

      // Merge the new data with the existing data
      setGraphData(prevData => ({
        nodes: [...prevData.nodes, ...data.nodes],
        links: [...prevData.links, ...data.links]
      }));
    } catch (error) {
      console.error(`Error fetching related nodes for ${node.id}:`, error);
    }
  };


  const isLeafNode = (nodeId, links) => {
    console.log(nodeId, links)
    // Filter links where the node is the source or target
    const relatedLinks = links.filter(link => link.source.id === nodeId || link.target.id === nodeId);

    console.log(relatedLinks)
    // If the node has no outgoing links and only one or zero incoming links, it's a leaf node
    const outgoingLinks = relatedLinks.filter(link => link.source.id === nodeId);
    const incomingLinks = relatedLinks.filter(link => link.target.id === nodeId);

    return outgoingLinks.length === 0 && incomingLinks.length <= 1;
  }

  const nodeCanvasObject = (node, ctx, globalScale) => {
    const label = node.label || node.name;
    const fontSize = 12 / globalScale;
    ctx.font = `${fontSize}px Arial`;
    const textWidth = ctx.measureText(label).width;

    // Define circle radius based on label length, with some padding
    const radius = Math.max(10);  // 10 for padding

    // Draw circle
    ctx.beginPath();
    ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = node.color || 'lightgray'; // node color or default to lightgray
    ctx.fill();

    // Draw node label in the circle
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'black';  // text color
    ctx.fillText(label, node.x, node.y);
  };
  const linkCanvasObject = (link, ctx, globalScale) => {
    // Fetch the start and end points of the link
    const start = link.source;
    const end = link.target;

    // Draw the link line
    ctx.beginPath();
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.strokeStyle = 'rgba(50, 50, 50, 0.5)';  // Adjust link color if needed
    ctx.lineWidth = 0.3;  // Adjust line width if needed
    link.distance = 10
    ctx.stroke();

    // Find the midpoint of the link for placing the label
    const middleX = (start.x + end.x) / 2;
    const middleY = (start.y + end.y) / 2;

    // Define the relation label
    const label = link.label || link.name || link.type || link.name | ""; // Use a default name if not provided in the link object

    // Set font settings for the label
    const fontSize = 10 / globalScale;
    ctx.font = `${fontSize}px Arial`;

    // Draw the relation label at the midpoint of the link
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'black';  // Adjust text color if needed
    ctx.fillText(label, middleX, middleY);
  };


  return (

    <div className="graph-chat-container">


      <div className="graph-component">
        <ForceGraph2D
          graphData={graphData}
          nodeAutoColorBy="type"
          nodeLabel={'id'}
          // onNodeClick={handleNodeClick}
          nodeCanvasObject={nodeCanvasObject}
          linkCanvasObject={linkCanvasObject}
          enableZoomPanInteraction={true}
          enableNodeDrag={true}
        />
      </div>

    </div>

  );
};

export default GraphViewer;
