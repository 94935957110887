import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Typed from 'typed.js';

const Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background: #091118; /* Dark background */
  color: white;
  padding: 20px;
  width:100%
`;

const Sentence = styled.p`
  font-size: 2rem; /* Large text */
  font-weight: 300;
  text-align: center;
  margin-top:200px;
    width:100%

`;

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 2px solid white; /* Underline style */
  color: white;
  font-size: 2rem;
  font-weight: 300;
  margin: 0 5px;
  text-align: center;
    width:56%;

  
  &:focus {
    outline: none;
    border-bottom: 3px solid yellow; /* Highlighted when focused */
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

export default function CreateAutomation() {
  // You can manage the state of inputs if needed
  const [firstInput, setFirstInput] = useState('');
  const [secondInput, setSecondInput] = useState('');
  const el = useRef(null);
  const typed = useRef(null);
  useEffect(() => {
    const options = {
      strings: [
        "Make a Dashboard on Global Inflation Trends Post COVID ",
        "     "
        // Add more strings here that describe other functionalities
      ],
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 1500,
      startDelay: 800,
      loop: true,
      showCursor: true,
      cursorChar: '|',
      contentType: 'html',
    };

    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
  }, []);

  return (
    <Container>
      <Sentence>
        {/* Make */}
        <span ref={el}></span>

        {/* <Input
          placeholder="Make Something from Nothing"
          value={firstInput}
          onChange={(e) => setFirstInput(e.target.value)}
        /> */}
        <br />
        <br />
        <br />
        <button className='border-2 border-white text-white px-4 hover:border-yellow-500'>Generate</button>
        {/* from
        <Input
          placeholder="Nothing"
          value={secondInput}
          onChange={(e) => setSecondInput(e.target.value)}
        /> */}
      </Sentence>
    </Container>
  );
}
