import React from 'react';
import { useCurrentFrame, useVideoConfig, interpolate, Img, Video } from 'remotion';

const RenderSection = ({ section }) => {
  const frame = useCurrentFrame();
  let style = {}; // Base style

  // Handle animations
  section.animations.forEach(animation => {
    if (animation.type === "fadeIn") {
      const opacity = interpolate(frame, [animation.startFrame, animation.startFrame + animation.duration], [0, 1]);
      style = { ...style, opacity };
    }
    // Implement other animations similarly
  });

  // Handle rendering based on type
  switch (section.type) {
    case "text":
      return <div style={{ ...style, ...section.style, position: 'absolute', left: section.position.x, top: section.position.y }}>{section.content}</div>;
    case "image":
      return <Img src={section.src} style={{ ...style, ...section.style, position: 'absolute', left: section.position.x, top: section.position.y }} />;
    // Handle other types (video, etc.) similarly
    default:
      return null;
  }
};

export const ConfigurableVideo = (props) => {
  const { width, height } = useVideoConfig();

  return (
    <div style={{ width, height, backgroundColor: '#000', position: 'relative' }}>
      {props?.sections.map(section => (
        <RenderSection key={section.id} section={section} />
      ))}
    </div>
  );
};
