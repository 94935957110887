import WebViewer from "../components/viewers/WebViewer";
import { useParams } from "react-router-dom";
import { useApi } from '../callApi';
import React, { useEffect, useState } from "react";

const WebViewerPage = ({ }) => {
  const { runId } = useParams(); // Retrieve the `runId` parameter from the URL
  const { callApi } = useApi();
  const [output, setOutput] = useState();
  const [currentApp, setCurrentApp] = useState()
  const [currentRun, setCurrentRun] = useState()

  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await callApi(`/user/runs/` + runId);
      console.log(response.data)
      setOutput(response.data);
      setCurrentApp(response.apps)
      setCurrentRun(response)

      setLoading(false);

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetch();
  }, []);


  return (
    <>



      {output && <WebViewer data={output} mode="fullscreen" />}

    </>
  )

}
export default WebViewerPage