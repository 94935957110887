import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from '../callApi';
import AppStatusIndicator from "./AppStatusIndicator";
import AppOutputPreview from "./AppOutputPreview";
import AppInput from "./AppInput";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingIndicator from "./shared/LoadingIndicator";
import ErrorIndicator from "./shared/ErrorIndicator";
import tracker from "../tracker";

export default function MainAppGeneratorContainer({ appId, appSlug, onBackButton, showBackButton = true, setOutputCallback = false }) {
  const { callApi } = useApi(true);
  const [currentApp, setCurrentApp] = useState(null);
  const [featuredThumbnail, setFeaturedThumbnail] = useState({})
  const [shouldRunStatusIndicator, setShouldRunStatusIndicator] = useState(false);


  const [output, setOutput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableGeneration, setDisableGeneration] = useState(false);

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAppDetails = async () => {
      try {
        setLoading(true);
        let param = appSlug ? `app_slug=${appSlug}` : `app_id=${appId}`
        const response = await callApi(`/apps/details?${param}`);
        setCurrentApp(response);
        setOutput(null)
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAppDetails();
  }, [appId]);

  const onGenerateClicked = async (inputData) => {
    if (!inputData)
      return alert('Invalid input');

    try {
      setShouldRunStatusIndicator(true)

      const response = await callApi(`/apps/run?app_slug=${appSlug}`, { 'method': 'POST' }, inputData);
      console.log(response)
      setOutput(response);
      if (setOutputCallback)
        setOutputCallback(response)
      tracker.track_event(appSlug, { "succesful": true })
    } catch (err) {
      console.error(err); // For debugging purposes
      const errorMessage = err.message || "An unexpected error occurred";
      setError(errorMessage);
      tracker.track_error('Error Generating ' + appSlug, errorMessage)

    } finally {
      setShouldRunStatusIndicator(false)
    }
  };



  if (error) {
    return <ErrorIndicator onClose={() => setError()} fullscreen={true} message={'Something went wrong !'} description={error} />
  }

  return (
    <section className="py-4 overflow-hidden w-full">
      <div className="container mx-auto px-4">
        <div className="bg-white p-6 border rounded-xl shadow">
          <div className="flex flex-col md:flex-row items-start justify-between px- pt-">
            <div className="mb-2">
              {currentApp && (
                <>
                  <h3 className="text-lg font-semibold"><img className="inline-block mr-2" width={'24px'} src={`/assets/images/${currentApp?.output_icon}`} />{currentApp.name}</h3>
                  <p className="text-sm text-gray-500">{currentApp.description}</p>
                </>
              )}
            </div>
            {showBackButton && <button onClick={() => onBackButton ? onBackButton() : window.location = '/apps'} className="flex items-center font-semibold text-gray-800 hover:text-gray-600 mb-4 md:mb-0">
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back to Apps
            </button>}

          </div>
          {loading && <LoadingIndicator message={'Loading...'} />}
          {!loading && <div className="px-6 pt-5 pb-7 divide-y divide-gray-200">
            {currentApp && !shouldRunStatusIndicator && <AppInput setDisableGeneration={setDisableGeneration} setFeaturedThumbnail={setFeaturedThumbnail} app={currentApp} onGenerateClicked={onGenerateClicked} />}
            {shouldRunStatusIndicator && <AppStatusIndicator steps={currentApp?.steps} />}
            {output && !setOutputCallback && featuredThumbnail.length && <img width={'40%'} src={featuredThumbnail} />}
            {output && !setOutputCallback && <AppOutputPreview setDisableGeneration={setDisableGeneration} appId={currentApp?.id} output={output} outputType={currentApp?.output_type} />}
          </div>}

        </div>
      </div>
    </section>
  );
}
