import { InfinitySpin } from 'react-loader-spinner';

const LoadingIndicator = ({ fullscreen, message }) => {
  return (
    <>
      {
        fullscreen && (
          <div className="fixed inset-0 bg-white bg-opacity-75 flex justify-center items-center">
            <InfinitySpin width="200" color="#FF2C53" />
            {message && <h2>{message}</h2>}

          </div>
        )
      }
      {
        !fullscreen && (
          <div className="flex justify-center items-center h-full">
            <InfinitySpin width="200" color="#FF2C53" />
            {message && <h2>{message}</h2>}

          </div>)
      }

    </>

  );
};

export default LoadingIndicator;
