import LandingHeader from "../components/shared/LandingHeader";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MainAppGeneratorContainer from "../components/MainAppGeneratorContainer";
import CreateAutomation from "../components/CreateAutomation";

const AutomaterPage = ({ }) => {
  const { appId, appSlug } = useParams(); // Retrieve the `runId` parameter from the URL


  return (
    <>
      <LandingHeader />

      <CreateAutomation />


    </>
  )

}
export default AutomaterPage