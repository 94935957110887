// ColorPicker.js
import React, { useState, useEffect, useRef } from 'react';
import { SwatchesPicker } from 'react-color';

const ColorPicker = ({ input, color, onColorChange }) => {
  const [showSwatches, setShowSwatches] = useState(false);
  const swatchRef = useRef(null);

  const handleSwatchClick = () => {
    setShowSwatches(!showSwatches);
  };

  const handleColorChange = (color) => {
    onColorChange(color);
    setShowSwatches(false); // Hide the picker after selection
  };


  const handleClose = () => {
    setShowSwatches(false);
  };

  const handleClickOutside = (event) => {
    if (swatchRef.current && !swatchRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (showSwatches) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSwatches]);

  return (
    <>
      <label className="input-label">{input?.label}</label>
      <div className="relative" ref={swatchRef}>
        <button
          onClick={handleSwatchClick}
          style={{ backgroundColor: color || '#192A53' }}
          className="w-10 h-10 border border-gray-300 cursor-pointer"
        />
        {showSwatches && (
          <div className="absolute z-10">
            <SwatchesPicker color={color} onChangeComplete={handleColorChange} />
          </div>
        )}
      </div>
    </>

  );
};

export default ColorPicker;
