import React, { useState } from "react";
import LandingHeader from "../components/shared/LandingHeader";
import { useApi } from '../callApi';
import LoadingIndicator from '../components/shared/LoadingIndicator';
import ErrorIndicator from '../components/shared/ErrorIndicator';
import tracker from "../tracker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function LandingPage() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false); // Added state to track email validity

  const { callApi } = useApi(true);

  // Regular expression for email validation
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setIsEmailValid(validateEmail(emailInput)); // Validate email and update state
  };

  const handleSubscribe = async () => {
    if (!isEmailValid) {
      return; // Prevent subscription if email is not valid
    }
    // Subscription logic goes here
    fetch();
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await callApi(`/join-waiting`, { 'method': 'POST' }, {
        email: email
      });


      setLoading(false);
      setSuccess(true)

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };




  return (
    <div className="h-screen bg-cover bg-center" style={{ backgroundImage: 'url("/assets/images/bg-empty.png")' }}>
      <LandingHeader showNavMenu={false} showLogo={false} />

      <div className="flex flex-col justify-center items-center h-full w-full text-center">
        {/* Use flex to align logo and title horizontally */}
        <div className="flex flex-row items-center gap-2 mt-12">
          {/* Logo to the left */}
          {/* Title and slogan */}
          <div>
            <img src={"/assets/logos/logo.png"} alt="MakeGen.AI Logo" className="h-24 md:h-24 lg:h-24 inline" />

            <h1 className="text-white text-5xl md:text-7xl lg:text-8xl font-bold m-0 inline align-middle" style={{ fontFamily: 'Poppins, sans-serif' }}>
              MakeGen.AI
            </h1>
            <p className="text-white text-xl md:text-3xl font-light mt-1" style={{ fontFamily: 'Poppins, sans-serif' }}>
              Make Something from Nothing.
            </p>
          </div>
        </div>

        {/* Subscription Form */}
        <div className="mt-8 bg-white bg-opacity-70 rounded p-5 shadow border-2 border-black max-w-md">
          <h2 className="text-xl font-bold mb-4" style={{ fontFamily: 'Poppins, sans-serif' }}>Join Our Waiting List</h2>
          <div className="flex flex-col items-center">


            {loading && (
              <LoadingIndicator message="Putting you on the list..." />)
            }
            {!loading && (
              <>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                  className="p-3 border-2 border-black rounded-full w-full mb-4"
                />

                {!success && (
                  <button
                    onClick={handleSubscribe}
                    disabled={!isEmailValid || loading || success} // Button is disabled if email is not valid, loading, or success state is true
                    className={`p-3 ${!isEmailValid || loading || success ? 'bg-gray-500' : 'bg-black'} text-white rounded-full w-full border-none`}>
                    Subscribe
                  </button>
                )}
                {success && (
                  <button disabled='true' className="p-3 bg-green text-xl text-green rounded-full w-full border-none">
                    <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                    Joined Succesfully !
                  </button>
                )}
                {error && (
                  <ErrorIndicator message={error} />
                )}

                <p className="mt-4" style={{ fontFamily: 'Poppins, sans-serif' }}>If you have been invited to the beta, use your email to log in.</p>
              </>
            )}


          </div>
        </div>
      </div>
    </div>
  );
}
