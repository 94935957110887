import React, { useState, useRef } from 'react';
import { useApi } from '../callApi';
import LoadingIndicator from './shared/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFileImage, faImage, faImagePortrait } from '@fortawesome/free-solid-svg-icons';
import { faMicrophone, faStop } from '@fortawesome/free-solid-svg-icons';
import { Radio } from 'react-loader-spinner';

const FileUploader = ({ uploadedFileUrl = null, compactMode = false, input, onFileSelect, setDisableGeneration, fileTypes }) => {
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(uploadedFileUrl);
  const [mediaUrl, setMediaUrl] = useState(uploadedFileUrl)

  const fileInputRef = useRef(null); // Create a ref for the file input
  const { uploadFile } = useApi(true);


  const handleFileInput = async (e, inputName) => {
    const file = e.target.files[0];

    // const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    // Allowed file types
    // const allowedTypes = fileTypes || input?.accept.split(',').map(type => type.trim());

    // if (file.size > maxFileSize) {
    //   alert("File size should not exceed 5MB.");
    //   return;
    // }

    // if (!allowedTypes.includes(file.type)) {
    //   alert("Unsupported file type.");
    //   return;
    // }


    const formData = new FormData();
    formData.append('file', file);
    console.log(formData)

    try {
      setLoading(true)
      setDisableGeneration(true)
      const response = await uploadFile('/upload', formData, {}); // Adjust endpoint as necessary
      if (response.mediaUrl) {

        onFileSelect(response.mediaUrl)
        setMediaUrl(response.mediaUrl)
        setLoading(false)
        setDisableGeneration(false)
        setUploadedFile(file.name)


      }
    } catch (error) {
      setDisableGeneration(true)
      const errorInfo = error instanceof Error ? error.toString() : (error.message || JSON.stringify(error, Object.getOwnPropertyNames(error)));
      alert(`Error uploading file: ${errorInfo}`);

      console.error('Error uploading file:', error);
    }
  };


  const handleButtonClick = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };

  return (

    <>
      {compactMode && (
        <button
          disabled={loading}
          onClick={() => { document.getElementById('file-uploader').click() }}
          className={`flex ml-2 items-center justify-center py-3 px-4 text-sm border-black rounded border-2 text-black  hover:bg-gray-200 transition duration-300 ease-in-out focus:outline-none ${(loading ? 'bg-blue-500' : 'bg-white')}`}

        >
          {loading ? (
            <Radio visible={true} colors={['#ffff', '#ffff', '#ffff']} width="18" height="18" wrapperStyle={{ display: 'inline-block' }} />
          ) : (
            <span className="flex-shrink-0">
              <FontAwesomeIcon color={loading ? 'red' : 'black'} icon={loading ? faStop : faImage} /> {/* Change icon based on recording state */}
            </span>

          )}

        </button>
      )}
      {!loading &&
        <>
          <label hidden={compactMode} className="input-label">{input?.label}</label>

          <input
            hidden={compactMode}
            id='file-uploader'
            key={input?.name || 'file'}
            type="file"
            accept={fileTypes || input?.accept}
            className="file:mr-4 file:py-2 file:px-4 file:border-2 file:border-black file:text-black file:bg-white hover:file:bg-gray-50"
            onChange={(e) => handleFileInput(e, input.name)}
            placeholder={input.placeholder}
          />
        </>

      }
      {loading && !compactMode && <LoadingIndicator message={'Uploading file..'} />}
      {!loading && uploadedFile && !compactMode && (
        <div className="uploaded-file-indicator">
          <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />
          <span className="file-name">{uploadedFile}</span>
          <img width={'128px'} src={mediaUrl} />
        </div>
      )}

    </>


  );
};

export default FileUploader;
