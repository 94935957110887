// App.js

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import ProtectedRoute from './ProtectedRoute';
import { Routes, Route, useLocation } from 'react-router-dom';
import AppRunViewerPage from './pages/AppRunViewerPage';
import NotFoundPage from './pages/NotFoundPage';
import LandingPage from './pages/LandingPage';
import PricingPage from './pages/PricingPage';
import Footer from './components/shared/PageFooter';
import SlidesViewerPage from './pages/SlideViewerPage';
import FAQPage from './pages/FAQPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

import DashboardPage from './pages/DashboardPage';
import AppRunListPage from './pages/AppRunListPage';
import AppListPage from './pages/AppListPage';
import AppGeneratorPage from './pages/AppGeneratorPage';
import AutomaterPage from './pages/AutomaterPage';
import SharePage from './pages/SharePage';
import mixpanel from 'mixpanel-browser';
import AppSelectorPage from './pages/AppSelectorPage';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from "@stripe/react-stripe-js";
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PaymentCancelPage from './pages/PaymentCancelPage';
import AppRunIteratePage from './pages/AppRunIteratePage';
import WebViewerPage from './pages/WebsiteViewerpage';
// import VideoPreview from './video-generator/VideoPreview';
import LandingGatePage from './pages/LandingGatePage';
// import VideoViewerPage from './pages/VideoViewerPage';
import Demo from './pages/Demo';
import BrandsListPage from './pages/brands/BrandsListPage';
import DemoVideo from './video-generator/DemoVideo';
import BrandEditorPage from './pages/brands/BrandEditorPage';
import PodcastPreviewPage from './pages/PodcastPreviewPage';


// const stripePromise = loadStripe('pk_live_51OrO4IBWfq3XHXwEzGUabxFyWrpd7H3Mq1SOYi9Wi9yrwkFJ9dWarX0DAdLi4IJdPaHN3SUnj8YdnK9AjKjCfh4P00YCVjYOhD');

function App() {
  const location = useLocation();

  const showFooter = location.pathname.indexOf('/user/runs/website') === -1;
  const environmentMode = process.env.REACT_APP_ENV_MODE || '';



  // Check if environment is production
  if (process.env.NODE_ENV === 'production') {
    mixpanel.init("27260e1793b8219a4a8feeb1764e000e", { debug: false }); // Set debug to false in production
    mixpanel.track('boot' + environmentMode, {
      environment: 'production' + environmentMode
    });
  } else {
    // Optional: Setup for development (e.g., logging for development)
    console.log("Mixpanel is disabled in development mode.");
  }

  return (
    <>
      {showFooter && (
        <HelmetProvider>
          {/* <Elements stripe={stripePromise}> */}
          <div className="flex flex-col min-h-screen">
            <div className="flex-grow">

              <Routes>
                <Route path="/landing" element={<LandingPage />} />
                <Route path="/" element={<LandingGatePage />} />
                {/* <Route path="/" element={<LandingPage />} /> */}

                {/* <Route path="/slides" element={<SlidesViewerPage />} />


                <Route path="/apps" element={<AppListPage />} />
                <Route path="/Demo" element={<Demo />} />
                <Route path="/podcast" element={<PodcastPreviewPage />} />
 */}
                {/* <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
                <Route path="/user/runs/:runId/" element={<ProtectedRoute><AppRunViewerPage /></ProtectedRoute>} />
                <Route path="/user/runs/iterate/:runId/" element={<ProtectedRoute><AppRunIteratePage /></ProtectedRoute>} />
                <Route path="/user/runs" element={<ProtectedRoute><AppRunListPage /></ProtectedRoute>} />
                <Route path="/user/payment/success" element={<ProtectedRoute><PaymentSuccessPage /></ProtectedRoute>} />
                <Route path="/user/payment/cancel" element={<ProtectedRoute><PaymentCancelPage /></ProtectedRoute>} />
                <Route path="/user/brands" element={<ProtectedRoute><BrandsListPage /></ProtectedRoute>} />
                <Route path="/user/brands/editor" element={<ProtectedRoute><BrandEditorPage></BrandEditorPage></ProtectedRoute>} />
                <Route path="/user/brands/:uuid" element={<ProtectedRoute><BrandEditorPage></BrandEditorPage></ProtectedRoute>} /> */}


                <Route path="/share/:runId" element={<SharePage />} />
                {/* 
                <Route path="/automation" element={<AutomaterPage />} />
                <Route path="/video" element={<DemoVideo />} />
                {/* <Route path="/user/brands/editor" element={<BrandEditorPage />} /> */}


                {/* <Route path="/apps" element={<AppListPage />} />
                <Route path="/apps/:outputType" element={<AppSelectorPage />} />

                <Route path="/apps/:appSlug/:appId" element={<AppGeneratorPage />} />

                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} /> */} */}

                {/* Catch all other routes */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>
            <Footer />

          </div>

          {/* </Elements> */}




        </HelmetProvider >
      )
      }

      {
        !showFooter &&
        <Routes>
          <Route path="/user/runs/website/:runId/" element={<ProtectedRoute><WebViewerPage /></ProtectedRoute>} />
        </Routes>
      }
    </>


  );
}

export default App;

