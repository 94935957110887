import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Assuming you're using react-router

const NotFoundPage = () => (
  <div className="flex items-center justify-center h-screen bg-gray-100">
    <div className="text-center">
      <h1 className="text-9xl font-bold text-black">404</h1>
      <p className="text-2xl md:text-3xl font-light text-gray-800 my-8">
        This page is missing or you assembled the link incorrectly.
      </p>
      <Link to="/dashboard" className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-black hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
        <FontAwesomeIcon icon={faHome} className="mr-2" />
        Back to Home
      </Link>
    </div>
  </div>
);

export default NotFoundPage;
