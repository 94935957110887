import LandingHeader from "../shared/LandingHeader";
import { useParams } from "react-router-dom";
import VideoPreview from "../../video-generator/VideoPreview";
import { Scene, SceneObject } from '../../video-generator/components/animationUtils';
import { useEffect, useState } from "react";

const DynamicVideoPreview = ({ config }) => {
  /// Scene 1: Discovering Egypt
  const [videoConfig, setVideoConfig] = useState()

  function extractJavaScriptCode(config) {
    const regex = /```javascript([\s\S]+?)```/;
    const match = config.match(regex);

    if (match && match[1]) {
      return match[1].trim(); // Extracted JavaScript code
    } else {
      return config; // Return original config if not found
    }
  }

  const code = extractJavaScriptCode(config)

  useEffect(() => {
    import('../../video-generator/components/animationUtils').then(({ Scene, SceneObject }) => {
      const scenes = eval(code)
      const sceneComponents = []
      scenes.forEach(scene => {
        if (scene) {
          sceneComponents.push(
            {
              "type": "VideoScene",
              "props": {
                "scene": scene

              },
              "durationInFrames": 150,
            }
          )

        }


      });
      setVideoConfig({
        components: sceneComponents
      });

    });

  }, [code])




  return (
    <>



      {videoConfig && <VideoPreview config={videoConfig} />}

    </>
  )

}
export default DynamicVideoPreview